import LOCAL_STORAGE_KEYS from 'constants/localStorageKeys';
import { CLEAR_MODAL, SET_IS_AUTHENTICATED, SET_MODAL } from 'store/types/common';
import { getFromLocalStorage } from 'utils/localStorage';

const initialState = {
  isAuthenticated: getFromLocalStorage(LOCAL_STORAGE_KEYS.TOKEN),
  modal: {
    isOpen: false,
    title: '',
    message: '',
    onConfirm: () => {},
    onCancel: () => {},
    confirmText: '削除',
    cancelText: 'キャンセル',
    type: 'confirm',
  },
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action?.payload,
      };
    case SET_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          ...action?.payload,
        },
      };
    case CLEAR_MODAL:
      return {
        ...state,
        modal: initialState.modal,
      };
    default:
      return state;
  }
};

export default globalReducer;
