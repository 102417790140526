import { Routes, Route, Navigate } from 'react-router-dom';
import AppRoutes from 'routes/routes';
import Login from 'pages/Login/Login';
import PrivateRoute from 'routes/PrivateRoute';
import { useSelector } from 'react-redux';
import Survey from 'pages/Survey/Survey';

const AppRoutesComponent = () => {
  const isAuthenticated = useSelector((state) => state.common.isAuthenticated);

  return (
    <Routes>
      <Route path={AppRoutes.login} element={isAuthenticated ? <Navigate to={AppRoutes.survey} replace /> : <Login />} />
      <Route
        path="*"
        element={
          <main id="main" className="main-content w-full bg-white-714-50 pt-main-top">
            <PrivateRoute
              element={
                <div className="max-w-main w-full mx-auto bg-white py-6 px-8">
                  <Routes>
                    <Route path={AppRoutes.survey} element={<Survey />} />
                  </Routes>
                </div>
              }
              isAuthenticated={isAuthenticated}
            />
          </main>
        }
      />
    </Routes>
  );
};

export default AppRoutesComponent;
