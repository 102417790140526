/**
 * Save an item to localStorage
 * @param {string} key - The key under which the value will be stored
 * @param {any} value - The value to be stored (will be stringified)
 */
export const saveToLocalStorage = (key, value) => {
  if (typeof value === 'object') {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    localStorage.setItem(key, value);
  }
};

/**
 * Get an item from localStorage
 * @param {string} key - The key of the item to retrieve
 * @returns {any} The value retrieved from localStorage or null if not found
 */
export const getFromLocalStorage = (key) => {
  const item = localStorage.getItem(key);
  if (item) {
    try {
      return JSON.parse(item);
    } catch (e) {
      return item;
    }
  }
  return null;
};

/**
 * Remove an item from localStorage
 * @param {string} key - The key of the item to remove
 */
export const removeFromLocalStorage = (key) => {
  localStorage.removeItem(key);
};

/**
 * Clear all items from localStorage
 */
export const clearLocalStorage = () => {
  localStorage.clear();
};
