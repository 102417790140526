import Button from 'components/Button';
import Input from 'components/Input';
import LOCAL_STORAGE_KEYS from 'constants/localStorageKeys';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import AppRoutes from 'routes/routes';
import { setIsAuthenticated } from 'store/actions/common';
import { saveToLocalStorage } from 'utils/localStorage';

const Login = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    saveToLocalStorage(LOCAL_STORAGE_KEYS.TOKEN, 'loged');
    dispatch(setIsAuthenticated('loged'));
  };

  return (
    <div id="login" className="flex items-center justify-center min-h-screen py-3">
      <form onSubmit={handleSubmit} className="p-6" aria-label="Login Form">
        <div className="flex items-end justify-center">
          <span className="leading-none text-32 font-bold text-logo">BabyTrack</span>
          <span className="leading-none ml-2 text-sm text-logo">管理サイト</span>
        </div>

        <div className="mt-8">
          <label htmlFor="email" className="block text-base text-black/85">
            メールアドレス
          </label>
          <div className="mt-2">
            <Input
              id="email"
              name="email"
              type="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              aria-invalid={email ? 'false' : 'true'}
            />
          </div>
        </div>

        <div className="mt-4 relative">
          <label htmlFor="password" className="block text-base text-black/85">
            パスワード
          </label>
          <div className="mt-2">
            <Input
              id="password"
              name="password"
              type="password"
              value={password}
              showPassword={showPassword}
              required
              onShowHidePassword={() => setShowPassword(!showPassword)}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>

        <div className="mt-4 flex justify-center">
          <a href="#" className="text-sm border-b border-primary-339 text-primary-339">
            パスワードをお忘れの方はこちら
          </a>
        </div>

        <Button
          type="submit"
          text="ログイン"
          className="mx-auto mt-8 py-2 w-60 h-10 border border-solid border-primary-423 shadow-subtle-4"
          color="primary"
        />

        <Button
          type="link"
          to={AppRoutes.register}
          text="新規アカウント作成"
          className="mx-auto mt-6 py-2 w-60 h-10 shadow-subtle-2 text-black/85"
          color="outline"
        />
      </form>
    </div>
  );
};

export default Login;
