import { memo } from 'react';

const ToastSuccess = ({ title }) => {
  return (
    <>
      <div className="flex items-center gap-3">
        <img src={require('assets/resources/CheckCircle.svg').default} alt="icon success" width={14} height={14} />
        <div>{title && <p className="text-logo text-sm font-normal">{title}</p>}</div>
      </div>
    </>
  );
};

export default memo(ToastSuccess);
