import Button from 'components/Button';
import Input from 'components/Input';
import Pagination from 'components/Pagination';
import Table, { IndeterminateCheckbox } from 'components/Table';
import { toastSuccess } from 'lib/toast';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { clearModal, setModal } from 'store/actions/common';

const _dataSample = Array.from({ length: 10 }, () => ({
  name: '3歳児検診',
  date: '2024/10/25',
  status: ['公開中', '非公開', '下書き'][Math.floor(Math.random() * 3)],
}));

const Survey = () => {
  const dispatch = useDispatch();
  const [dataSample, setDataSample] = useState(_dataSample);
  const [selectedRows, setSelectedRows] = useState([]);

  const handleRowSelect = (selectedRow) => {
    setSelectedRows((prevSelected) => {
      const isSelected = prevSelected.find((row) => row.id === selectedRow.id);
      if (isSelected) {
        return prevSelected.filter((row) => row.id !== selectedRow.id);
      } else {
        return [...prevSelected, { id: selectedRow.id, ...selectedRow.original }];
      }
    });
    selectedRow.getToggleSelectedHandler();
  };

  const handleSelectAll = (table) => {
    setSelectedRows(() => {
      if (selectedRows.length < table.getRowCount()) {
        const rows = table.getRowModel();
        return rows.rows.map((row) => ({ id: row.id, ...row.original }));
      } else {
        return [];
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: '-',
        header: ({ table }) => (
          <IndeterminateCheckbox
            className="m-auto"
            {...{
              checked: selectedRows.length === table.getRowCount(),
              indeterminate: selectedRows.length > 0,
              onChange: () => handleSelectAll(table),
            }}
          />
        ),
        size: 48,
        enableSorting: false,
        cell: ({ row }) => (
          <div className="flex justify-center">
            <IndeterminateCheckbox
              className="m-auto"
              {...{
                checked: selectedRows.some((item) => item.id === row.id),
                indeterminate: row.getIsSomeSelected(),
                onChange: () => handleRowSelect(row),
              }}
            />
          </div>
        ),
      },
      {
        accessorKey: 'name',
        header: () => <span>問診票名</span>,
        cell: ({ row, getValue }) => (
          <div className="flex justify-between items-center text-sm">
            <span>{getValue()}</span>
            <span
              className={`text-xs rounded-sm py-0.5 px-2 border border-solid ${
                row.original.status === '公開中'
                  ? 'text-primary-346 border-primary-346/1 bg-primary-346/7'
                  : row.original.status === '下書き'
                    ? 'text-logo border-gray-651 bg-table-header'
                    : 'text-danger-229 border-danger-576 bg-danger-736'
              }`}
            >
              {row.original.status}
            </span>
          </div>
        ),
        size: 584,
        sortingFn: () => {
          // console.log(e);
        },
      },
      {
        accessorKey: 'date',
        size: 144,
        header: () => <span>最終編集日</span>,
        sortingFn: () => {
          // console.log(e);
        },
      },
      {
        accessorKey: '-',
        header: () => <span></span>,
        enableSorting: false,
        size: 280,
        cell: ({ row }) => (
          <div className="flex justify-between items-center text-sm">
            <span className="text-primary-346 rounded-sm py-0.5 px-2 cursor-pointer">回答結果一覧</span>
            <span className="text-primary-346 rounded-sm py-0.5 px-2 cursor-pointer">編集</span>
            <span
              className="text-danger-229 rounded-sm py-0.5 px-2 cursor-pointer"
              onClick={() => {
                dispatch(
                  setModal({
                    isOpen: true,
                    title: '削除すると復元できません。本当に削除してもよろしいですか？',
                    type: 'delete',
                    confirmText: '削除',
                    cancelText: 'キャンセル',
                    onConfirm: () => {
                      setDataSample((prevState) => {
                        return prevState.filter((item, index) => index !== row.index);
                      });
                      setSelectedRows((prevState) => {
                        return prevState.filter((selected) => selected.id !== row.id);
                      });
                      toastSuccess('1件の問診票が削除されました');
                      dispatch(clearModal());
                    },
                    onCancel: () => {
                      dispatch(clearModal());
                    },
                  })
                );
              }}
            >
              削除
            </span>
          </div>
        ),
      },
    ],
    [selectedRows]
  );
  return (
    <section id="question-list">
      <h1 className="text-2xl font-bold text-black/85">問診票一覧</h1>
      <div className="flex items-end justify-between gap-2 mt-2">
        <p className="text-sm opacity-85 text-gray-336">1 - 10 / 100 件</p>
        <div className="flex gap-4">
          <Button
            icon={<img src={require('assets/resources/Plus.svg').default} alt="plus icon" width={10.5} height={10.5} />}
            text="問診票を作成"
            color="primary"
          />
        </div>
      </div>
      <form aria-labelledby="search-form" className="mt-6">
        <div className="w-1/3 relative">
          <Input type="search" placeholder="問診票を検索" />
        </div>
      </form>
      <div className="w-full mt-4">
        <div>
          {selectedRows.map((item, index) => (
            <div className="p-4 w-full border-2 border-solid border-primary-423 rounded-md mb-2 flex justify-between items-center" key={index}>
              <p className="text-secondary-354 text-sm">{item.name}</p>
              <div className="flex">
                <div className="flex gap-4">
                  <Button
                    className="text-sm text-black/85"
                    icon={<img src={require('assets/resources/FileSave.svg').default} alt="file save icon" width={14} height={14} />}
                    text="CSVファイルダウンロード"
                    color="outline"
                  />
                  <Button
                    className="text-sm"
                    icon={<img src={require('assets/resources/Trash.svg').default} alt="trash icon" width={14} height={14} />}
                    text="削除"
                    color="danger-outline"
                    onClick={() => {
                      dispatch(
                        setModal({
                          isOpen: true,
                          title: '削除すると復元できません。本当に削除してもよろしいですか？',
                          type: 'delete',
                          confirmText: '削除',
                          cancelText: 'キャンセル',
                          onConfirm: () => {
                            setDataSample((prevState) => {
                              return prevState.filter((_, index) => index !== Number(item.id));
                            });
                            setSelectedRows((prevState) => {
                              return prevState.filter((selected) => selected.id !== item.id);
                            });
                            toastSuccess('1件の問診票が削除されました');
                            dispatch(clearModal());
                          },
                          onCancel: () => {
                            dispatch(clearModal());
                          },
                        })
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        <Table columns={columns} data={dataSample} />
      </div>
      <div className="mt-5">
        <Pagination currentPage={6} totalPages={50} onPageChange={() => {}} />
      </div>
    </section>
  );
};

export default Survey;
