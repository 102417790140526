import { Link } from 'react-router-dom';
import AppRoutes from 'routes/routes';

const Button = ({
  text = '',
  className = '',
  type = 'submit',
  color = 'primary',
  to = AppRoutes.home,
  icon = <></>,
  children = <></>,
  onClick = () => {},
}) => {
  const colorStyles = {
    primary: 'bg-primary-346 text-white',
    outline: 'bg-transparent border border-solid border-gray-651',
    danger: 'bg-danger-229 text-white',
    'danger-outline': 'bg-transparent border border-solid border-danger-229 text-danger-229',
  };

  if (type === 'link') {
    return (
      <Link
        to={to}
        type={type}
        onClick={onClick}
        className={`flex items-center justify-center py-1.5 px-4 rounded-sm text-base ${colorStyles[color]} ${className}`}
      >
        {icon ? <>{icon}&nbsp;</> : <></>}
        <span>{text}</span>
        {children ?? <></>}
      </Link>
    );
  }

  return (
    <button type={type} onClick={onClick} className={`flex items-center justify-center py-1.5 px-4 rounded-sm ${colorStyles[color]} ${className}`}>
      {icon ? <>{icon}&nbsp;</> : <></>}
      <span>{text}</span>
      {children ?? <></>}
    </button>
  );
};

export default Button;
