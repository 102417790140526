import { createStore, applyMiddleware, combineReducers } from 'redux';
import { thunk } from 'redux-thunk';
import commonReducer from 'store/reducers/common';

const rootReducer = combineReducers({
  common: commonReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
