const Input = ({
  type = 'text',
  value,
  onChange,
  showPassword,
  onShowHidePassword,
  required = false,
  placeholder,
  accept,
  className = '',
  id = '',
  options = {},
}) => {
  const handleType = () => {
    if (['search'].includes(type)) return 'text';
    else if (type === 'password' && showPassword) return 'text';
    return type;
  };

  let icon = null;

  if (type === 'search') {
    icon = (
      <div className="h-full w-10 absolute top-0 right-0 flex justify-center items-center border border-solid border-gray-300 cursor-pointer hover:bg-gray-200">
        <img src={require('assets/resources/Search.svg').default} alt="icon search" width={14} height={14} />
      </div>
    );
  }

  if (type === 'password') {
    icon = (
      <button type="button" onClick={onShowHidePassword} className="absolute right-3 bottom-3">
        {showPassword ? (
          <img src={require('assets/resources/EyeVisibilityOn.svg').default} alt="eye visibility off" width={12.83} height={11.55} />
        ) : (
          <img src={require('assets/resources/EyeVisibilityOff.svg').default} alt="eye visibility off" width={12.83} height={11.55} />
        )}
      </button>
    );
  }

  return (
    <div className={`w-100 h-10 relative ${className}`}>
      <input
        id={id}
        type={handleType()}
        className={`w-full h-full focus:outline-none placeholder:text-base placeholder:text-secondary-354 border border-solid border-gray-651 px-3 py-2 ${icon ? 'pr-11' : ''}`}
        placeholder={placeholder}
        required={required}
        value={value}
        onChange={onChange}
        accept={accept}
        {...options}
      />
      {icon}
    </div>
  );
};

export default Input;
