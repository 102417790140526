import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import AppRoutes from 'routes/routes';
import { clearModal, setIsAuthenticated, setModal } from 'store/actions/common';
import { clearLocalStorage } from 'utils/localStorage';

const noHeaderPaths = [AppRoutes.login];

const Header = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const isAuthenticated = useSelector((state) => state.common.isAuthenticated);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = () => {
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleLogout = () => {
    const _handleLogout = () => {
      clearLocalStorage();
      dispatch(setIsAuthenticated(false));
    };

    dispatch(
      setModal({
        isOpen: true,
        title: 'ログアウトしますか？',
        cancelText: 'キャンセル',
        onCancel: () => dispatch(clearModal()),
        confirmText: 'ログアウト',
        onConfirm: () => {
          dispatch(clearModal());
          _handleLogout();
        },
      })
    );
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const shouldShowHeader = !noHeaderPaths.includes(location.pathname);

  return (
    <>
      {shouldShowHeader && (
        <header data-testid="header" id="header" className="bg-white h-header sticky top-0 z-50">
          <div className="h-full w-full border border-solid border-header flex justify-between items-center px-8">
            <div className="flex items-center gap-10">
              <NavLink to={AppRoutes.home} className="flex items-end">
                <span className="text-2xl font-bold text-logo">BabyTrack</span>
                <span className="ml-2 font-bold text-sm text-logo">管理サイト</span>
              </NavLink>
              {isAuthenticated && (
                <nav className="flex">
                  <ul className="flex space-x-2">
                    <li>
                      <NavLink
                        to={AppRoutes.survey}
                        className={({ isActive }) =>
                          isActive
                            ? 'text-sm text-primary-346 border-primary-346 py-3 px-5 border-b-2'
                            : 'text-sm text-black/85 border-transparent py-3 px-5 border-b-2'
                        }
                      >
                        問診票一覧
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={AppRoutes.answerList}
                        className={({ isActive }) =>
                          isActive
                            ? 'text-sm text-primary-346 border-primary-346 py-3 px-5 border-b-2'
                            : 'text-sm text-black/85 border-transparent py-3 px-5 border-b-2'
                        }
                      >
                        回答結果一覧
                      </NavLink>
                    </li>
                  </ul>
                </nav>
              )}
            </div>
            {isAuthenticated && (
              <div className="relative" ref={dropdownRef}>
                <div className="flex items-center gap-2 cursor-pointer" onClick={toggleDropdown}>
                  <img src={require('assets/resources/UserOutlined.svg').default} alt="user outlined" width={13.22} height={13.99} />
                  <span className="text-sm text-logo">example@gmail.com</span>
                  <button className={`focus:outline-none transition-transform ${isOpen ? 'rotate-180' : ''}`}>
                    <img src={require('assets/resources/CaretDownOutlined.svg').default} alt="icon caret down outlined" width={18} height={18} />
                  </button>
                </div>
                {isOpen && (
                  <div className="absolute right-0 mt-1 w-52 py-1 bg-white rounded-sm shadow-soft-md">
                    <ul>
                      <li
                        onClick={() => handleOptionClick('アカウント情報設定')}
                        className="px-3 py-1.5 cursor-pointer text-sm text-logo hover:bg-white-795"
                      >
                        アカウント情報設定
                      </li>
                      <li onClick={handleLogout} className="px-3 py-1.5 cursor-pointer text-sm text-logo hover:bg-white-795">
                        ログアウト
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
        </header>
      )}
    </>
  );
};

export default Header;
