import { toast } from 'react-toastify';
import ToastSuccess from 'lib/toast/success';

const options = {
  hideProgressBar: true,
  className: '!min-h-0 rounded-sm',
  bodyClassName: '!m-0 py-1 px-2.5',
  borderRadius: '2px',
  closeButton: <></>,
};

export const toastSuccess = (title = '') => {
  return toast(<ToastSuccess title={title} />, { ...options });
};
