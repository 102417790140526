const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };
  const getPageNumbers = () => {
    let pageNumbers = [];

    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, startPage + 4);

    const correctedStartPage = endPage - 4 < 1 ? 1 : startPage;

    for (let i = correctedStartPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  const pageNumbers = getPageNumbers();

  return (
    <div id="pagination" className="flex items-center justify-center space-x-1 mt-4">
      {/* Previous Page Button */}
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className={`h-8 w-8 border rounded-sm flex justify-center items-center ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-200'}`}
      >
        <img className="rotate-180" src={require('assets/resources/ArrowRight.svg').default} width={6.38} height={10.35} alt="Previous Page" />
      </button>

      {/* First Page Button and Ellipsis */}
      {currentPage > 3 && (
        <>
          <button
            onClick={() => handlePageChange(1)}
            className="h-8 w-8 flex justify-center items-center border rounded-sm hover:bg-gray-200 text-sm"
          >
            1
          </button>
          <span className="h-8 w-8 flex justify-center items-center text-sm">
            <img src={require('assets/resources/Ellipsis.svg').default} alt="ellipsis" width={19} height={32} />
          </span>
        </>
      )}

      {/* Current Page Range */}
      {pageNumbers.map((pageNumber) => (
        <button
          key={pageNumber}
          onClick={() => handlePageChange(pageNumber)}
          className={`h-8 w-8 flex justify-center items-center border rounded-sm text-sm ${currentPage === pageNumber ? 'text-primary-346 border-primary-346' : 'hover:bg-gray-200'}`}
        >
          {pageNumber}
        </button>
      ))}

      {/* Last Page Button and Ellipsis */}
      {currentPage < totalPages - 2 && (
        <>
          <span className="h-8 w-8 flex justify-center items-center text-sm">...</span>
          <button
            onClick={() => handlePageChange(totalPages)}
            className="h-8 w-8 flex justify-center items-center border rounded-sm hover:bg-gray-200 text-sm"
          >
            {totalPages}
          </button>
        </>
      )}

      {/* Next Page Button */}
      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={`h-8 w-8 flex justify-center items-center border rounded-sm text-sm ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-200'}`}
      >
        <img src={require('assets/resources/ArrowRight.svg').default} width={6.38} height={10.35} alt="Next Page" />
      </button>
    </div>
  );
};

export default Pagination;
