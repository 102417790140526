import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from 'routes/AppRoutes';
import Header from 'components/Header';
import Modal from 'components/Modal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <Router>
      <Header />
      <div data-testid="app">
        <AppRoutes />
      </div>
      <Modal />
      <ToastContainer />
    </Router>
  );
};

export default App;
