import React from 'react';

import { useReactTable, getCoreRowModel, getSortedRowModel, flexRender } from '@tanstack/react-table';

const SortIcon = ({ type = '' }) => {
  return (
    <>
      <svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.6921 5.63806L4.72356 1.03629C4.60997 0.90457 4.39124 0.90457 4.27644 1.03629L0.307898 5.63806C0.160467 5.80965 0.293396 6.06101 0.53146 6.06101H8.46854C8.7066 6.06101 8.83953 5.80965 8.6921 5.63806Z"
          fill="black"
          fillOpacity={type === 'asc' ? '1' : '0.25'}
        />
      </svg>
      <svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.46854 0.9375H0.53146C0.293396 0.9375 0.160467 1.18886 0.307898 1.36046L4.27644 5.96222C4.39003 6.09394 4.60876 6.09394 4.72356 5.96222L8.6921 1.36046C8.83953 1.18886 8.7066 0.9375 8.46854 0.9375Z"
          fill="black"
          fillOpacity={type === 'desc' ? '1' : '0.25'}
        />
      </svg>
    </>
  );
};

const Table = ({ columns, data }) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div className="w-full">
      <table className="w-full opacity-85">
        <thead className="bg-table-header border-b border-solid border-black/6">
          {table.getHeaderGroups().map((headerGroup, index) => (
            <tr key={index}>
              {headerGroup.headers.map((header, index) => {
                return (
                  <th
                    className="text-sm font-normal text-left py-4"
                    key={index}
                    style={{ width: header.getSize() }}
                    colSpan={header.colSpan}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {header.isPlaceholder ? null : (
                      <div className="flex items-center justify-between border-r border-solid border-black/6 px-4 text-black/85">
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {header.column.getCanSort() && (
                          <div className="pl-2">
                            <SortIcon type={header.column.getIsSorted()} />
                          </div>
                        )}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, index) => {
            return (
              <tr key={index} className="hover:bg-table-header">
                {row.getVisibleCells().map((cell, index) => {
                  return (
                    <td className="p-4 border-b border-solid border-black/6 text-sm text-black/85" key={index}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;

export const IndeterminateCheckbox = ({ indeterminate, className = '', ...rest }) => {
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return <input type="checkbox" ref={ref} className={className + ' cursor-pointer'} {...rest} />;
};
