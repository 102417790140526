import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import Button from 'components/Button';
import { useRef } from 'react';

const Modal = () => {
  const modalRef = useRef(null);
  const { isOpen, title, message, onConfirm, onCancel, confirmText, cancelText, type } = useSelector((state) => state.common.modal);

  if (!isOpen) {
    return null;
  }

  const clickBackdrop = () => {
    modalRef.current.classList.add('animate-fade-out');
    setTimeout(() => {
      modalRef.current.classList.remove('animate-fade-out');
    }, 300);
  };

  return ReactDOM.createPortal(
    <div id="modal" className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-max">
      <div onClick={clickBackdrop} className="fixed inset-0 backdrop" />
      <div ref={modalRef} className="bg-white rounded-sm shadow-lg p-6 relative max-w-md w-full modal-content">
        <div className="flex items-start">
          <div className="text-orange-500 mr-2 text-2xl min-w-6 min-h-6">
            <img src={require('assets/resources/ExclamationCircle.svg').default} alt="icon modal" width={22} height={22} />
          </div>
          <h2 className="text-base font-bold text-logo">{title}</h2>
        </div>
        <p className="mt-6 text-gray-700">{message}</p>
        <div className="mt-6 flex justify-end gap-2">
          {cancelText && <Button onClick={onCancel} text={cancelText} color="outline" className="text-sm text-black/85" />}
          {confirmText && (
            <Button
              onClick={onConfirm}
              text={confirmText}
              color={type === 'confirm' ? 'primary' : 'danger'}
              className={`text-sm border border-solid ${type === 'confirm' ? 'border-primary-423' : ''}`}
            />
          )}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default Modal;
