import { CLEAR_MODAL, SET_IS_AUTHENTICATED, SET_MODAL } from 'store/types/common';

export const setIsAuthenticated = (isAuthenticated) => {
  return {
    type: SET_IS_AUTHENTICATED,
    payload: isAuthenticated,
  };
};

export const setModal = (modal) => {
  return {
    type: SET_MODAL,
    payload: modal,
  };
};

export const clearModal = () => {
  return {
    type: CLEAR_MODAL,
    payload: null,
  };
};
